import React from "react";
import "./changelog.css";

const Changelog = () => {
  const changelogData = [
    {
      version: "v1.1.6",
      changes: "Event streaming support added for chat mode.",
    },
    {
      version: "v1.1.5",
      changes:
        "Moved chat feature to sidePanel, Added Support for File Context",
    },
    {
      version: "v1.1.4",
      changes: "Minor change",
    },
    {
      version: "v1.1.3",
      changes: "New UI, Select Code to Chat Feature added",
    },
    {
      version: "v1.1.2",
      changes: "Minor Fixes",
    },
    {
      version: "v1.1.1",
      changes:
        "Multiple Languages Support, html support for Code AutoComplete and Chat bug fixed",
    },
    {
      version: "v1.1.0",
      changes: "Code AutoComplete",
    },
    {
      version: "v1.0.9",
      changes: "Comment Question Based Inline Suggestions",
    },
    {
      version: "v1.0.8",
      changes: "Spotlight UI Updated and chat history bug fixed.",
    },
    {
      version: "v1.0.5",
      changes: "Retrieval augmented generation (RAG) added to Realtime Chat.",
    },
    {
      version: "v1.0.4",
      changes: "Token expiry issue fixed",
    },
    {
      version: "v1.0.3",
      changes:
        "Chat History Feature Added, UI updated and chat and spotlight icons not appearing on load (fixed)",
    },
    {
      version: "v1.0.2",
      changes: "Minor Bugs Fixes",
    },
    {
      version: "v1.0.1",
      changes: "Extension is totally updated",
    },
  ];

  return (
    <div className="changelog_page" id="changelog">
      {/* <div className="main_page_features_tag">Changelog</div> */}
      <div className="changelog_page_h1tag">
        Track Our Progress and Updates
      </div>
      <div className="changelog-container">
        {changelogData.map((item, index) => (
          <div key={index} className="changelog-item">
            <div className="version-badge">{item.version}</div>
            <div className="changelog-content">
              <p>{item.changes}</p>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Changelog;
