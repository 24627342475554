import React from "react";
import logo_white from "../assets/images/gs copilot logo whit.png";

function Services() {
  return (
    <div className="main_page_service" id="services">
      <div className="main_page_service_tag">Services</div>
      <h1 className="main_page_service_text">
        The competitive advantage developers ask for by name.
      </h1>
      <div className="main_page_service_list">
        <div className="main_page_service_list_item_longated">
          <h1 className="main_page_service_list_item_title">
            Proven to increase developer productivity and accelerate the pace of
            software development.
          </h1>
        </div>
        <div className="main_page_service_list_item" id="main_page_list_item1">
          <div>
            <h1 className="main_page_service_listitem_h1">35%</h1>
            <p className="main_page_service_listitem_ptag">Faster Coding</p>
          </div>
        </div>
        <div
          className="main_page_service_list_item"
          id="main_page_service_list_item_extra"
        >
          <div className="main_page_service_list_item_logodiv">
            <img
              src={logo_white}
              alt="white logo"
              className="main_page_service_list_item_logo"
            />
          </div>
          <div>
            <h1 className="main_page_service_list_item_h1tag">
              Designed by leaders in AI so you can build with confidence.
            </h1>
          </div>
        </div>
        <div className="main_page_service_list_item_longated">
          <h1 className="main_page_service_list_item_title">
            Google Apps Script Copilot provides code suggestions for Google Apps
            Script, making it easier to write scripts.
          </h1>
        </div>
      </div>
      <h1 className="main_page_service_text">Our Standards.</h1>
      <div className="main_page_service_standards">
        <div className="main_page_service_standards_item">
          <h1 className="main_page_service_standards_item_h1tag">
            24/7 Support
          </h1>
          <p className="main_page_service_standards_item_ptag">
            Our team is always available to help you with any questions or
            concerns.
          </p>
        </div>
        <div className="main_page_service_standards_item">
          <h1 className="main_page_service_standards_item_h1tag">
            99.9% Uptime
          </h1>
          <p className="main_page_service_standards_item_ptag">
            Our service is always available, so you can focus on your work.
          </p>
        </div>
        <div className="main_page_service_standards_item">
          <h1 className="main_page_service_standards_item_h1tag">Secure</h1>
          <p className="main_page_service_standards_item_ptag">
            We take security seriously and ensure your data is always safe.
          </p>
        </div>
      </div>
    </div>
  );
}

export default Services;
