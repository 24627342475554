import React from "react";
import inline_suggestion from "../assets/videos/inline suggestion.mp4";
import chat_feature from "../assets/videos/chat feature.mp4";
import spotlight_feature from "../assets/videos/spotlight feature.mp4";
import comment_base_suggestion from "../assets/videos/comment based suggestions.mp4";

function Features() {
  return (
    <div className="main_page_features" id="features">
      <div className="main_page_features_tag">Features</div>
      <div className="main_page_features_h1tag">
        The AI coding assistant elevating developer workflows.
      </div>
      <div className="main_page_feature_1">
        <div className="main_page_feature_1_Text">
          <h3 className="main_page_feature_1_Text_h1">
            {" "}
            Inline Suggestions for Streamlined Coding!
          </h3>
          <p className="main_page_feature_1_Text_ptag">
            The Inline Suggestion feature in GS Copilot intelligently assists
            developers by suggesting code snippets, syntax corrections, and
            relevant functions as they type. This real-time guidance helps
            reduce coding errors and accelerates workflow, ensuring a seamless
            coding experience tailored to each developer's unique style and
            project needs. With inline suggestions, you can focus more on
            creativity and problem-solving while GS Copilot handles routine
            coding tasks efficiently.
          </p>
        </div>
        <video
          className="main_page_feature_1_video"
          src={inline_suggestion}
          autoPlay
          muted
          loop
          playsInline
          width="640"
          height="360"
        ></video>
      </div>
      <div className="main_page_feature_1" id="main_page_feature_alternative1">
        <video
          className="main_page_feature_1_video"
          src={chat_feature}
          autoPlay
          muted
          loop
          playsInline
        ></video>
        <div className="main_page_feature_1_Text">
          <h3 className="main_page_feature_1_Text_h1">
            Interactive Chat Assistance for Enhanced Problem-Solving
          </h3>
          <p className="main_page_feature_1_Text_ptag">
            The Chat feature in GS Copilot offers developers an interactive,
            conversational interface to ask questions, troubleshoot code, and
            explore solutions in real time. By leveraging AI-driven insights,
            the chat provides contextual answers, code examples, and
            explanations to support both learning and project-specific
            problem-solving. This feature helps developers tackle complex issues
            more effectively, streamlining the coding process and enhancing
            productivity with responsive, on-demand support.
          </p>
        </div>
      </div>
      <div className="main_page_feature_1">
        <div className="main_page_feature_1_Text">
          <h3 className="main_page_feature_1_Text_h1">
            Elevate Your Apps Script Workflow with GS
            Copilot Spotlight Feature
          </h3>
          <p className="main_page_feature_1_Text_ptag">
            The Spotlight feature in GS Copilot is a powerful, intuitive tool
            designed to streamline your coding workflow. It allows developers to
            quickly locate, insert, modify, and refine code snippets without
            disrupting their focus. With intelligent search capabilities,
            Spotlight enables instant access to relevant code snippets,
            documentation, and project files. Additionally, it empowers users to
            edit and enhance code on the fly, making development more efficient
            by minimizing context switching and reducing reliance on external
            resources.
          </p>
        </div>
        <video
          className="main_page_feature_1_video"
          src={spotlight_feature}
          autoPlay
          muted
          loop
          playsInline
        ></video>
      </div>
      <div className="main_page_feature_1" id="main_page_feature_alternative2">
        <video
          className="main_page_feature_1_video"
          src={comment_base_suggestion}
          autoPlay
          muted
          loop
          playsInline
        ></video>
        <div className="main_page_feature_1_Text">
          <h3 className="main_page_feature_1_Text_h1">
            Comment-Based Suggestions for Context-Aware Code Generation.
          </h3>
          <p className="main_page_feature_1_Text_ptag">
            The Comment-Based Suggestions feature in GS Copilot allows
            developers to write natural-language comments that the AI interprets
            to generate relevant code snippets and solutions. By understanding
            the intent described in comments, GS Copilot provides context-aware
            recommendations, saving time on boilerplate coding and helping to
            align code with the developer’s specific requirements.
          </p>
        </div>
      </div>
    </div>
  );
}

export default Features;
