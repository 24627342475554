import React from "react";
import { Link } from "react-router-dom";

function ContactUs() {
  return (
    <div className="main_page_contactcard_section" id="contact">
      <div className="main_page_contactcard">
        <Link className="main_page_contactcard_button" to="/contact">
          Contact Us <i className="fas fa-arrow-right"></i>
        </Link>
        <p className="main_page_contactcard_ptag">
          We're here to help you with any questions or concerns you may have.
        </p>
      </div>
    </div>
  );
}

export default ContactUs;
