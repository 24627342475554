import React from "react";
import "./content.css";
// import Pricing from "./Pricing";
import Home from "./Home";
import Services from "./Services";
import Features from "./Features";
import ContactUs from "./ContactUs";
import Footer from "./Footer";

const Content = () => {
  return (
    <div className="content" id="content">
      <Home />
      <Services />
      <Features />
      {/* <Pricing /> */}
      <ContactUs />
      <Footer />
    </div>
  );
};

export default Content;